
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  FormLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom"
// react component that creates a switch button that changes from on to off mode
import Switch from "react-bootstrap-switch";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from 'axios'
import { loadFromLocalStorage, saveToLocalStorage } from "redux/reducers/auth";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-notifications/dist/react-notifications';
import {Redirect} from 'react-router-dom';
import Select from 'react-select';
import {URL} from "../../constants/globals";


class AddConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_offset: 0,
      redirect: null,
    }
  };
  textInput = React.createRef();
  token = loadFromLocalStorage("token");
  headers = { 
    'Authorization': 'token ' + this.token,
  };

  roles = [
    {label: 'admin', value: 'admin'},
    {label: 'data_collector', value: 'data_collector'},
  ]

  componentDidMount() {    
    axios.get('/api/data_entry/configuration/', {'headers': this.headers})
      .then(res => {  
        this.setState({ api_offset: res.data });     
      });
  }  
   
  handleSave = () => {
    let form_data = new FormData();
    form_data.append('api_offset', this.textInput.current.value);
    let url = '/api/data_entry/configuration/';
    axios.post(url, form_data, {
      headers: {
          'Authorization': 'token ' + this.token,
      }
    }).then(res => {
      this.setState({ api_offset: res.data });
      this.createNotification('success', 'API offset updated successfully!', '')
      return
    }).catch(err => {console.log("Error"); console.log(err)})
  }

  createNotification = (type, title, content) => {
    switch (type) {
        case 'info':
        return NotificationManager.info(content);
        case 'success':
        return NotificationManager.success(content, title);
        case 'warning':
        return NotificationManager.warning(content, title, 3000);
        case 'error':
        return NotificationManager.error(content, title, 5000);
    }
  };

  render() {
    
    return (
      <div className="main-content">
        <Container fluid>
          <div className="d-flex">
            <FormLabel className="mx-auto h1 "><b>Update Configuration</b></FormLabel>
          </div>
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={{ span: 12 }}>
              <Card
                tableFullWidth
                textCenter
                content={
                  <Table responsive>
                    <tbody>
                      <tr>
                            <td><span>API Offset</span></td>
                            <td><input type="text" placeholder={"Current offset: "+this.state.api_offset} ref={this.textInput}></input></td>  
                      </tr>  
                      
                    </tbody>
                  </Table>
                }
                legend={
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" className="btn-fill" onClick={() => this.handleSave()}>Save</Button>
                  </div>
                }
              />
            </Col>
          </Row>
          <NotificationContainer/>
        </Container>
      </div>
    );
  }
}

export default AddConfiguration;
